define('banco/adapters/application', ['exports', 'ember', 'ember-data', 'banco/config/environment', 'ember-simple-auth/mixins/data-adapter-mixin'], function (exports, _ember, _emberData, _bancoConfigEnvironment, _emberSimpleAuthMixinsDataAdapterMixin) {
  exports['default'] = _emberData['default'].JSONAPIAdapter.extend(_emberSimpleAuthMixinsDataAdapterMixin['default'], {

    appManager: _ember['default'].inject.service('mbAppManager'),
    authorizer: 'authorizer:application',
    host: _bancoConfigEnvironment['default'].apiHost,

    pathForType: function pathForType(type) {
      return _ember['default'].String.underscore(_ember['default'].String.pluralize(type));
    },

    handleResponse: function handleResponse(status) {
      var error = {};
      if (status < 200 || status > 399) {
        error.status = status;
        error.url = arguments[3] && arguments[3].url ? arguments[3].url : '?';
        error.method = arguments[3] && arguments[3].method ? arguments[3].method : '?';
        error.type = arguments[2] && arguments[2].type ? arguments[2].type : '?';
        error.message = arguments[2] && arguments[2].message ? arguments[2].message : '?';
        error.backtrace = arguments[2] && arguments[2].backtrace ? arguments[2].backtrace : '?';
        this.get('appManager').addServerError(error);
      }
      return this._super.apply(this, arguments);
    }

  });
});