define('banco/initializers/mb-app-manager', ['exports', 'banco/services/mb-app-manager'], function (exports, _bancoServicesMbAppManager) {
  exports.initialize = initialize;

  function initialize() /* application */{
    // application.inject('route', 'foo', 'service:foo');
  }

  exports['default'] = {

    name: 'mb-app-manager',

    initialize: function initialize() {
      var application = arguments[1] || arguments[0];
      application.register('mb-app-manager:service', _bancoServicesMbAppManager['default']);

      ['controller', 'component', 'route', 'router', 'service'].forEach(function (injectionTarget) {
        application.inject(injectionTarget, 'appManager', 'mb-app-manager:service');
      });
    }

  };
});