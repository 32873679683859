define('banco/routes/pf/projetos/lista-aguardando-autorizacao/index', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
  //import { rotaDeVisualizacaoPara } from '../../../../helpers/rota-de-visualizacao-para';

  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    model: function model() {
      return this.store.findAll('projeto');
    },
    actions: {
      editClicked: function editClicked(projeto) {
        this.transitionTo('pf.projetos.lista-aguardando-autorizacao.view', projeto);
      }
    }
  });
});