define('banco/router', ['exports', 'ember', 'banco/config/environment'], function (exports, _ember, _bancoConfigEnvironment) {

  var Router = _ember['default'].Router.extend({
    location: _bancoConfigEnvironment['default'].locationType,
    rootURL: '/banco/'
  });

  Router.map(function () {

    this.route('login');
    this.route('logout');

    this.route('pj', function () {});

    this.route('pf', function () {

      this.route('protocolar-pedido');

      this.route('projetos', function () {

        this.route('lista-aguardando-autorizacao', function () {
          this.route('view', { path: ':id' });
        });
        this.route('lista-aguardando-projeto', function () {
          this.route('view', { path: ':id' });
        });
        this.route('lista-em-analise', function () {
          this.route('view', { path: ':id' }, function () {
            this.route('empresa-proponente', function () {
              this.route('titularidade');
              this.route('dados-gerais');
              this.route('diretoria-e-conselho', function () {
                this.route('diretorias', function () {
                  this.route('new');

                  this.route('view', {
                    path: ':id'
                  }, function () {
                    this.route('edit');
                  });
                });

                this.route('conselhosAdministrativos', function () {
                  this.route('new');
                });
              });
              this.route('controle-societario', function () {
                this.route('new');

                this.route('view', {
                  path: ':id'
                }, function () {
                  this.route('edit');
                });
              });
              this.route('outras-informacoes');
              this.route('grupos-economicos', function () {
                this.route('new');
              });
            });
            this.route('projeto', function () {
              this.route('dados-gerais');
              this.route('usos-e-fontes', function () {
                this.route('usos', function () {
                  this.route('investimento-em-capital-fixo', function () {
                    this.route('new');
                  });

                  this.route('lista-capital-circulante', function () {
                    this.route('view', {
                      path: ':id'
                    }, function () {
                      this.route('edit');
                    });
                  });

                  this.route('posicao-contabil', function () {
                    this.route('edit');
                  });
                });
                this.route('fontes', function () {
                  this.route('recursos-proprios', function () {
                    this.route('view', {
                      path: ':id'
                    }, function () {
                      this.route('edit');
                    });
                    this.route('new');
                  });

                  this.route('recursos-terceiros', function () {
                    this.route('view', {
                      path: ':id'
                    }, function () {
                      this.route('edit');
                    });
                    this.route('new');
                  });
                });
                this.route('quadro-analitico');
              });
              this.route('cronogramas', function () {
                this.route('fisico');
                this.route('financeiro');
                this.route('resumo');
                this.route('desembolso');
                this.route('reembolso');
              });
              this.route('producao', function () {
                this.route('linha-de-producao', function () {
                  this.route('new');
                });
                this.route('destino-da-producao');
              });
              this.route('financiamento');
              this.route('outras-informacoes');
            });
            this.route('signatarios', function () {
              this.route('new');

              this.route('view', {
                path: ':id'
              }, function () {
                this.route('edit');
              });
            });
            this.route('validacoes');
          });
        });
        this.route('lista-aprovado', function () {});
        this.route('lista-arquivado', function () {});
        this.route('lista-desistencia', function () {});
      });
    });
  });

  exports['default'] = Router;
});