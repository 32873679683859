define('banco/routes/pf/projetos/lista-em-analise/view/empresa-proponente/controle-societario/new', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({

    model: function model(params) {
      var projeto = this.currentModel;
      return this.store.createRecord('acionista', {
        projeto: projeto,
        parent: null
      });
    },
    actions: {
      // save(acionista){
      save: function save(novoAcionista) {
        this.transitionTo('pf.projetos.lista-em-analise.view.empresa-proponente.controle-societario');
        // var projeto = this.currentModel;
        // var acionista = this.store.createRecord('acionista', {
        //   cpf: novoAcionista.cpf,
        //   nome: novoAcionista.nome,
        //   percentualParticipacao: novoAcionista.percentualParticipacao,
        //   estrangeiro: novoAcionista.estrangeiro,
        //   outro: novoAcionista.outro,
        //   pessoaJuridica: novoAcionista.pessoaJuridica,
        //   projeto: projeto,
        //   parent: null
        // });
        // acionista.save().then(function(){
        //   this.transitionTo('pf.projetos.lista-em-analise.view.empresa-proponente.controle-societario');
        // });
      },
      cancel: function cancel(contato) {
        this.transitionTo('pf.projetos.lista-em-analise.view.empresa-proponente.controle-societario');
      }
    }
  });
});