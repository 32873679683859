define('banco/helpers/format-date', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Helper.extend({

    intl: _ember['default'].inject.service(),
    appManager: _ember['default'].inject.service('mb-app-manager'),

    compute: function compute(params, hash) {
      var result = undefined;
      var date = typeof params[0] === 'undefined' ? '' : params[0];
      if (date === '') {
        return '';
      }
      result = this.get('intl').formatDate(date, hash);
      return result.replace(/\//g, this.get('appManager').get('dateSeparator'));
    }

  });
});