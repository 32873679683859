define('banco/components/form-diretoria-e-conselho', ['exports', 'banco/components/form-base'], function (exports, _bancoComponentsFormBase) {
  // import Ember from 'ember';
  // import FormBaseComponent from 'form-base';

  exports['default'] = _bancoComponentsFormBase['default'].extend({});

  // import Ember from 'ember';
  //
  // export default Ember.Component.extend({
  //   classNames: ['form-group'],
  //   model: {},
  //   header: '',
  //   actions: {
  //     save(contato){
  //       this.sendAction('onSave', contato);
  //     }
  //   }
  // });
});