define('banco/routes/pf/projetos/lista-em-analise/view/empresa-proponente/controle-societario/view/edit', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    actions: {
      save: function save(acionista) {
        this.transitionTo('pf.projetos.lista-em-analise.view.empresa-proponente.controle-societario');
        // acionista.save().then(function(){
        //   this.transitionTo('pf.projetos.lista-em-analise.view.empresa-proponente.controle-societario');
        // });
      },
      cancel: function cancel(contato) {
        this.transitionTo('pf.projetos.lista-em-analise.view.empresa-proponente.controle-societario');
      }
    }
  });
});