define('banco/models/acionista', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({

    cpf: _emberData['default'].attr('string'),
    cnpj: _emberData['default'].attr('string'),
    nome: _emberData['default'].attr('string'),
    pessoaJuridica: _emberData['default'].attr('boolean'),
    percentualParticipacao: _emberData['default'].attr('number'),
    estrangeiro: _emberData['default'].attr('boolean'),
    outro: _emberData['default'].attr('boolean'),
    controladora: _emberData['default'].attr('boolean'),

    projeto: _emberData['default'].belongsTo('projeto', { async: true }),
    parent: _emberData['default'].belongsTo('acionista', { async: true }),

    acionistas: _emberData['default'].hasMany('acionista', { async: true })

  });
});