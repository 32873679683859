define('banco/models/municipio', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({

    nome: _emberData['default'].attr('string'),
    codigo: _emberData['default'].attr('string'),
    microrregiao: _emberData['default'].attr('string'),
    semiarido: _emberData['default'].attr('boolean'),
    mesorregiaoDiferenciada: _emberData['default'].attr('string'),
    ride: _emberData['default'].attr('string'),
    areaAbrangida: _emberData['default'].attr('string'),

    estado: _emberData['default'].belongsTo('estado', { async: true })

  });
});