define("banco/helpers/rota-de-visualizacao-para", ["exports", "ember"], function (exports, _ember) {
  exports.rotaDeVisualizacaoPara = rotaDeVisualizacaoPara;

  var TransicaoDoProjeto = {
    "Aguardando_Autorizacao": "pf.projetos.lista-aguardando-autorizacao.view",
    "Aguardando_Projeto": "pf.projetos.lista-aguardando-projeto.view",
    "Em_Analise": "pf.projetos.lista-em-analise.view"
  };

  function rotaDeVisualizacaoPara(projeto) {
    return TransicaoDoProjeto[projeto.status];
  }

  exports["default"] = _ember["default"].Helper.helper(rotaDeVisualizacaoPara);
});