define('banco/models/outras-informacoes-empresa', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({

    objetivoSocial: _emberData['default'].attr('string'),
    historico: _emberData['default'].attr('string'),
    historicoComplemento: _emberData['default'].attr('string'),
    atividadeEconomica: _emberData['default'].attr('string'),
    informacoesComplementares: _emberData['default'].attr('string'),

    projeto: _emberData['default'].belongsTo('projeto', { async: true })

  });
});