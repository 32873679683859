define('banco/models/agente-operador', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({

    cnpj: _emberData['default'].attr('string'),
    nome: _emberData['default'].attr('string'),
    sigla: _emberData['default'].attr('string'),
    observacoes: _emberData['default'].attr('string'),

    agencias: _emberData['default'].hasMany('agencia', { async: true })

  });
});