define('banco/mixins/mb-route-application', ['exports', 'ember', 'ember-simple-auth/mixins/application-route-mixin', 'banco/mixins/mb-route-base'], function (exports, _ember, _emberSimpleAuthMixinsApplicationRouteMixin, _bancoMixinsMbRouteBase) {
  exports['default'] = _ember['default'].Mixin.create(_emberSimpleAuthMixinsApplicationRouteMixin['default'], _bancoMixinsMbRouteBase['default'], {

    intl: _ember['default'].inject.service(),

    beforeModel: function beforeModel() {
      this.get('intl').setLocale('pt-br');
      // Por algum motivo, dentro do serviço appManager não está sendo setada
      // a propriedade notifications, e por isso tive que por essa linha abaixo
      this.appManager.set('notifications', this.notifications);
      // ----------------------------------------------------------------------
      this.appManager.configNotifications(true, 2400);
    },

    actions: {

      willTransition: function willTransition() {
        this.send('clearStore');
      },

      didTransition: function didTransition() {
        this.appManager.enableInput();
      },

      clearStore: function clearStore() {
        // Cada rota define o que fazer...
      }

    }

  });
});