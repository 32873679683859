define('banco/routes/application', ['exports', 'ember', 'banco/mixins/mb-route-application'], function (exports, _ember, _bancoMixinsMbRouteApplication) {

  // -----------------------------------------------------------------------
  // TODO - mb-input-label: onChange
  //                   stringPattern "w10t|d3t|,1f"
  //                   numeric
  //                   scale
  //
  // TODO - mb-input-date-label: Deixar a usabilidade igual ao componente Delphi
  // -----------------------------------------------------------------------

  exports['default'] = _ember['default'].Route.extend(_bancoMixinsMbRouteApplication['default'], {

    model: function model() {
      return _ember['default'].RSVP.hash({
        configuracao: this.store.findRecord('configuracao', 1),
        avisos: this.store.query('aviso', { categoria: 'Agente Operador' }),
        appManager: this.appManager
      });
    },

    actions: {
      logout: function logout() {
        this.transitionTo('logout');
      }
    }

  });
});