define('banco/routes/login', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({

    actions: {
      willTransition: function willTransition(transition) {
        console.log('willTransition to ' + transition.targetName);
      }
    },

    beforeModel: function beforeModel() {
      if (this.appManager.get('currentUser')) {
        this.transitionTo(this.appManager.get('currentUser.tipo') === 'funcionario_banco' ? 'pf.index' : 'pj.index');
      }
    },

    model: function model() {
      return this.modelFor('application').avisos;
    }

  });
});