define('banco/models/pessoa-contato', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({

    nome: _emberData['default'].attr('string'),
    email: _emberData['default'].attr('string'),
    telefone: _emberData['default'].attr('string'),
    fax: _emberData['default'].attr('string'),

    empresa: _emberData['default'].belongsTo('empresa', { async: true })

  });
});