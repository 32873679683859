define('banco/services/component-focus/focus-manager', ['exports', 'ember-component-focus/services/component-focus/focus-manager'], function (exports, _emberComponentFocusServicesComponentFocusFocusManager) {
  /* Copyright 2015 LinkedIn Corp. Licensed under the Apache License, Version
   * 2.0 (the "License"); you may not use this file except in compliance with
   * the License. You may obtain a copy of the License at
   * http://www.apache.org/licenses/LICENSE-2.0
   *
   * Unless required by applicable law or agreed to in writing, software
   * distributed under the License is distributed on an "AS IS" BASIS,
   * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
   */

  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _emberComponentFocusServicesComponentFocusFocusManager['default'];
    }
  });
});