define('banco/models/signatario', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({

    nome: _emberData['default'].attr('string'),
    cargo: _emberData['default'].attr('string'),
    unidadeOrganizacional: _emberData['default'].attr('string'),
    sigla: _emberData['default'].attr('string'),
    preambulo: _emberData['default'].attr('string'),
    procurador: _emberData['default'].attr('boolean'),

    projeto: _emberData['default'].belongsTo('projeto', { async: true })

  });
});