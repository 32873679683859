define('banco/components/mb-view-notification', ['exports', 'ember', 'ember-keyboard-shortcuts/mixins/component'], function (exports, _ember, _emberKeyboardShortcutsMixinsComponent) {
  exports['default'] = _ember['default'].Component.extend(_emberKeyboardShortcutsMixinsComponent['default'], {

    keyboardShortcuts: {
      'esc': {
        action: 'closeMessage',
        preventDefault: false
      }
    },

    actions: {
      closeMessage: function closeMessage() {
        this.appManager.clearAllNotifications();
        return false;
      }
    }

  });
});