define('banco/utils/mb-error', ['exports'], function (exports) {
  function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError('Cannot call a class as a function'); } }

  var MbError = function MbError(name, message) {
    _classCallCheck(this, MbError);

    this.name = name;
    this.message = message;
  };

  exports.MbError = MbError;
  var mbErrorNames = {

    DB_RECORD_NOT_FOUND: 'DbRecordNotFound',
    DB_ERROR_PARSE_DOC_ID: 'DbErrorParseDocId',

    LOGIN_INVALID_USER: 'LoginInvalidUser',
    LOGIN_INACTIVE: 'LoginInactive'

  };
  exports.mbErrorNames = mbErrorNames;
});