define('banco/mirage/config', ['exports'], function (exports) {
  exports['default'] = function () {

    this.urlPrefix = 'http://localhost:3000';
    this.timing = 200;

    // Avisos ------------------------------------------------------------

    this.get('/api/common/avisos', function (db) {
      return {
        data: db['avisos'].map(function (attrs) {
          return { type: 'avisos',
            id: attrs.id,
            attributes: attrs
          };
        })
      };
    });

    // Configurações -----------------------------------------------------

    this.get('/api/common/configuracoes/:id', function (db, request) {
      return {
        data: {
          type: 'configuracoes',
          id: '1',
          attributes: db['configuracoes'].find(request.params.id)
        }
      };
    });

    // Consultas Prévias -------------------------------------------------

    this.get('/api/cp/consultas_previas', function (db, request) {
      var arr = request.queryParams.termoEnquadramento.split('/');
      var seq = arr.length > 0 ? arr[0] === '' ? 0 : arr[0] : 0;
      var ano = arr.length > 1 ? arr[1] === '' ? 0 : arr[1] : 0;
      var att = null;
      var dta = null;
      var resultSet = {
        data: db['consultas-previas'].where({ codigo: request.queryParams.codigo.toUpperCase() }).map(function (attrs) {
          return { type: 'consultas-previas',
            id: attrs.id,
            attributes: attrs
          };
        })
      };
      if (resultSet.data.length > 0) {
        att = resultSet.data[0].attributes;
        dta = new Date(att['data-aprovacao'] === '' ? '1900-01-01' : att['data-aprovacao']);
        if (ano.toString() !== dta.getFullYear().toString() || seq.toString() !== att['sequencial-termo-enquadramento'].toString()) {
          resultSet.data.splice(0, 1);
        }
      }
      return resultSet;
    });

    // Projetos ----------------------------------------------------------

    this.get('/api/banco/projetos', function (db) {
      return {
        data: db['projetos'].map(function (attrs) {
          return { type: 'projetos',
            id: attrs.id,
            attributes: attrs
          };
        })
      };
    });

    this.get('/api/banco/projetos/:id', function (db, request) {
      return {
        data: {
          type: 'projetos',
          id: request.params.id,
          attributes: db['projetos'].find(request.params.id)
        }
      };
    });

    this.post('/api/banco/projetos', function (db, request) {
      var data = JSON.parse(request.requestBody).data;
      return { data: db.projetos.insert(data) };
    });

    // Login -------------------------------------------------------------

    this.post('/usuarios/sign_in.json', function (db, request) {
      if (request.requestBody.indexOf('cnpj') === -1) {
        return {
          id: 1,
          token: 'e1PLc1gcNmziKLDX58DB',
          tipo: 'funcionario_banco',
          login: '12345678991',
          email: 'cpf.91@bancoteste.com.br',
          nome: 'Almir de Souza Melo',
          apelido: 'Almir',
          agenciaId: 1
        };
      } else {
        return {
          id: 2,
          token: 'pxQtjN3SPNgfoatXs-Wr',
          tipo: 'banco',
          login: '00110220000130',
          email: 'cnpj@bnb.com.br',
          nome: 'Banco do Nordeste',
          apelido: 'BNB'
        };
      }
    });
  };

  // These comments are here to help you get started. Feel free to delete them.

  /*
    Config (with defaults).
     Note: these only affect routes defined *after* them!
  */
  // this.urlPrefix = '';    // make this `http://localhost:8080`, for example, if your API is on a different server
  // this.namespace = '';    // make this `api`, for example, if your API is namespaced
  // this.timing = 400;      // delay for each request, automatically set to 0 during testing

  /*
    Route shorthand cheatsheet
  */
  /*
    GET shorthands
     // Collections
    this.get('/contacts');
    this.get('/contacts', 'users');
    this.get('/contacts', ['contacts', 'addresses']);
     // Single objects
    this.get('/contacts/:id');
    this.get('/contacts/:id', 'user');
    this.get('/contacts/:id', ['contact', 'addresses']);
  */

  /*
    POST shorthands
     this.post('/contacts');
    this.post('/contacts', 'user'); // specify the type of resource to be created
  */

  /*
    PUT shorthands
     this.put('/contacts/:id');
    this.put('/contacts/:id', 'user'); // specify the type of resource to be updated
  */

  /*
    DELETE shorthands
     this.del('/contacts/:id');
    this.del('/contacts/:id', 'user'); // specify the type of resource to be deleted
     // Single object + related resources. Make sure parent resource is first.
    this.del('/contacts/:id', ['contact', 'addresses']);
  */

  /*
    Function fallback. Manipulate data in the db via
       - db.{collection}
      - db.{collection}.find(id)
      - db.{collection}.where(query)
      - db.{collection}.update(target, attrs)
      - db.{collection}.remove(target)
     // Example: return a single object with related models
    this.get('/contacts/:id', function(db, request) {
      var contactId = +request.params.id;
       return {
        contact: db.contacts.find(contactId),
        addresses: db.addresses.where({contact_id: contactId})
      };
    });
   */

  /*
  You can optionally export a config that is only loaded during tests
  export function testConfig() {
  
  }
  */
});