define('banco/routes/pf/projetos/lista-em-analise/view/empresa-proponente/diretoria-e-conselho/diretorias/view/edit', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    actions: {
      save: function save(diretoria) {
        this.transitionTo('pf.projetos.lista-em-analise.view.empresa-proponente.diretoria-e-conselho');
        // diretoria.save().then(function(){
        //   this.transitionTo('pf.projetos.lista-em-analise.view.empresa-proponente.diretoria-e-conselho');
        // });
      },
      cancel: function cancel(contato) {
        contato.rollBack();
        this.transitionTo('pf.projetos.lista-em-analise.view.empresa-proponente.diretoria-e-conselho');
      }
    }
  });
});