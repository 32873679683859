define('banco/models/funcionario', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({

    cpf: _emberData['default'].attr('string'),
    nome: _emberData['default'].attr('string'),
    apelido: _emberData['default'].attr('string'),
    fone: _emberData['default'].attr('string'),
    cargoFuncao: _emberData['default'].attr('string'),
    tipoGestaoSolicitada: _emberData['default'].attr('string'),
    gestaoCentral: _emberData['default'].attr('boolean'),

    agenteOperador: _emberData['default'].belongsTo('agenteOperador', { async: true }),
    agencia: _emberData['default'].belongsTo('agencia', { async: true }),
    projetos: _emberData['default'].hasMany('projeto', { async: true }),
    usuario: _emberData['default'].belongsTo('usuario', { async: true })

  });
});