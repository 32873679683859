define('banco/routes/pf/projetos/lista-arquivado/index', ['exports', 'ember'], function (exports, _ember) {

  var Projeto = _ember['default'].Object.extend({
    nome: '',
    registro: '',
    consultaPrevia: '',
    dataLimite: ''
  });

  var ProjetoCollection = _ember['default'].ArrayProxy.extend({
    sortProperties: ['dataLimite'],
    sortAscending: false,
    content: []
  });

  var projeto1 = Projeto.create({
    id: 4,
    nome: 'Uma empresa que aguarda S.A. arquivado',
    registro: '12.334.445/2343-49 - PE',
    consultaPrevia: 'B334R3',
    dataLimite: '23/09/2015',
    termoDeEnquadramento: 'asfasfasefsef',
    empresaTitular: 'babaaabababa',
    municipio: 'GG',
    dataAprovacaoDaConsultaPrevia: '01/01/2014',
    numeroResolucaoSUDENE: '3434343434',
    dataDoPedido: '02/02/2015',
    ObservacaoPedido: 'blah' });
  var projeto2 = Projeto.create({
    id: 3,
    nome: 'Gertrudes macale inc. arquivado',
    registro: '12.334.445/2343-49 - PE',
    consultaPrevia: 'CGD556',
    dataLimite: '21/08/2015',
    termoDeEnquadramento: 'asfasfasefsef',
    empresaTitular: 'babaaabababa',
    municipio: 'GG',
    dataAprovacaoDaConsultaPrevia: '01/01/2014',
    numeroResolucaoSUDENE: '3434343434',
    dataDoPedido: '02/02/2015',
    ObservacaoPedido: 'blah'
  });

  var projetos = ProjetoCollection.create();
  projetos.pushObject(projeto1);
  projetos.pushObject(projeto2);
  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      return projetos;
    },
    actions: {
      editClicked: function editClicked(projeto) {
        this.transitionTo('pf.projetos.lista-arquivado.view', projeto);
      }
    }
  });
});