define('banco/components/form-base', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['form-group'],
    model: {},
    header: '',
    actions: {
      save: function save(item) {
        var self = this;
        item.save().then(function () {
          this.sendAction('onSave', item);
        }, function () {
          self.notifications.error('Erro ao salvar');
        })['catch'](function (reason) {
          self.notifications.error('Erro ao salvar - ' + reason.message);
        });
      },
      cancel: function cancel(item) {
        this.sendAction('onCancel', item);
        // item.deleteRecord().then(function(){
        //   this.sendAction('onCancel', item);
        // }, function(){
        //   self.notifications.error('Erro ao cancelar');
        // });
      }
    }
  });
});