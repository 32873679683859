define('banco/services/ember-devtools', ['exports', 'ember'], function (exports, _ember) {
  var Service = _ember['default'].Service;
  exports['default'] = Service.extend({
    renderedComponents: {},
    init: function init() {
      this.global = this.global || window;
      this.console = this.console || window.console;
      if (_ember['default'].getOwner) {
        // for ember > 2.3
        Object.defineProperty(this, 'container', {
          get: function get() {
            return _ember['default'].getOwner(this);
          }
        });
      }
      if (typeof DS === 'object') {
        this.store = this.lookup('service:store') || this.lookup('store:main'); // for ember-data < 2
        this.typeMaps = this.store.typeMaps;
      }
    },
    consoleLog: function consoleLog() {
      var _console;

      (_console = this.console).log.apply(_console, arguments);
    },
    app: function app() {
      var name = arguments.length <= 0 || arguments[0] === undefined ? 'main' : arguments[0];

      return this.lookup('application:' + name);
    },
    route: function route(name) {
      name = name || this.currentRouteName();
      return this.lookup('route:' + name);
    },
    controller: function controller(name) {
      name = name || this.currentRouteName();
      return this.lookup('controller:' + name);
    },
    model: function model(name) {
      var controller = this.controller(name);
      return controller && controller.get('model');
    },
    service: function service(name) {
      return this.lookup('service:' + name);
    },
    router: function router() {
      var name = arguments.length <= 0 || arguments[0] === undefined ? 'main' : arguments[0];

      return this.lookup('router:' + name).get('router');
    },
    routes: function routes() {
      return Object.keys(this.router().recognizer.names);
    },
    component: function component(idDomElementOrSelector, type) {
      if (typeof idDomElementOrSelector === 'object') {
        idDomElementOrSelector = idDomElementOrSelector.id;
      }
      return this.lookup('component:' + type + '::' + idDomElementOrSelector);
    },
    currentRouteName: function currentRouteName() {
      return this.controller('application').get('currentRouteName');
    },
    currentPath: function currentPath() {
      return this.controller('application').get('currentPath');
    },
    log: function log(promise, property, getEach) {
      var _this = this;

      return promise.then(function (value) {
        _this.global.$E = value;
        if (property) {
          value = value[getEach ? 'getEach' : 'get'].call(value, property);
        }
        _this.consoleLog(value);
      }, function (err) {
        _this.console.error(err);
      });
    },
    lookup: function lookup(name) {
      return this.container.lookup(name);
    },
    resolveRegistration: function resolveRegistration(name) {
      return this.container.resolveRegistration
      // ember < 2.3.1
      ? this.container.resolveRegistration(name)
      // previous ember versions
      : this.container.lookupFactory(name);
    },
    containerNameFor: function containerNameFor(object) {
      var cache =
      // ember 2.3.1
      _ember['default'].get(this.container, '__container__.cache')
      // previous ember versions
       || _ember['default'].get(this.container, '_defaultContainer.cache') || this.container.cache;
      var keys = Object.keys(cache);
      for (var i = 0; i < keys.length; i++) {
        if (cache[keys[i]] === object) return keys[i];
      }
    },
    inspect: _ember['default'].inspect,
    logResolver: function logResolver() {
      var bool = arguments.length <= 0 || arguments[0] === undefined ? true : arguments[0];

      _ember['default'].ENV.LOG_MODULE_RESOLVER = bool;
    },
    logAll: function logAll() {
      var bool = arguments.length <= 0 || arguments[0] === undefined ? true : arguments[0];

      var app = this.app();
      app.LOG_ACTIVE_GENERATION = bool;
      app.LOG_VIEW_LOOKUPS = bool;
      app.LOG_TRANSITIONS = bool;
      app.LOG_TRANSITIONS_INTERNAL = bool;
      this.logResolver(bool);
    },
    logRenders: function logRenders() {
      var self = this;

      _ember['default'].subscribe('render', {
        before: function before(name, start, payload) {
          return start;
        },
        after: function after(name, end, payload, start) {
          var id = payload.containerKey;
          if (!id) return;

          var duration = Math.round(end - start);
          var color = self.colorForRender(duration);
          var logId = 'renderedComponents.' + id;
          var ocurrences = self.get(logId);

          if (!ocurrences) {
            self.set(logId, []);
          }

          self.get(logId).push(duration);

          console.log('%c rendered ' + id + ' in ' + duration + 'ms', 'color: ' + color);
        }
      });
    },
    colorForRender: function colorForRender(duration) {
      var ok = '#000000';
      var warning = '#F1B178';
      var serious = '#E86868';

      if (duration < 300) return ok;
      if (duration < 600) return warning;

      return serious;
    },
    environment: function environment() {
      return this.resolveRegistration('config:environment');
    },
    globalize: function globalize() {
      var _this2 = this;

      var props = ['app', 'container', 'store', 'typeMaps', 'route', 'controller', 'model', 'service', 'routes', 'view', 'component', 'currentRouteName', 'currentPath', 'log', 'lookup', 'resolveRegistration', 'containerNameFor', 'inspect', 'logResolver', 'logAll'];
      // don't stomp on pre-existing global vars
      var skipGlobalize = this.constructor.skipGlobalize;
      if (skipGlobalize === null) {
        skipGlobalize = this.constructor.skipGlobalize = props.filter(function (prop) {
          return !_ember['default'].isNone(_this2.global[prop]);
        });
      }
      var self = this;
      props.map(function (name) {
        if (skipGlobalize.indexOf(name) !== -1) return;
        var prop = _this2[name];
        if (typeof prop === 'function') {
          prop = function () {
            // arguments variable is wrong if we use an arrow function here
            self[name].apply(self, arguments);
          };
        }
        _this2.global[name] = prop;
      });
    }
  }).reopenClass({
    skipGlobalize: null
  });
});
/* global DS */