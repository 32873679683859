define('banco/helpers/format-cpf', ['exports', 'ember'], function (exports, _ember) {
  exports.formatCpf = formatCpf;

  function formatCpf(params) {
    var cpf = params.toString();
    if (cpf.length !== 11) {
      return cpf;
    } else {
      return cpf.substr(0, 3) + '.' + cpf.substr(3, 3) + '.' + cpf.substr(6, 3) + '-' + cpf.substr(9, 2);
    }
  }

  exports['default'] = _ember['default'].Helper.helper(formatCpf);
});