define('banco/components/mb-input-date', ['exports', 'banco/config/environment', 'ember-cli-bootstrap-datepicker/components/bootstrap-datepicker'], function (exports, _bancoConfigEnvironment, _emberCliBootstrapDatepickerComponentsBootstrapDatepicker) {

  var self;

  exports['default'] = _emberCliBootstrapDatepickerComponentsBootstrapDatepicker['default'].extend({

    weekStart: 1,
    format: _bancoConfigEnvironment['default'].APP.dateFormat,
    language: _bancoConfigEnvironment['default'].APP.language,
    todayHighlight: true,
    daysOfWeekHighlighted: [0, 6],

    onChange: function onChange() {},

    keyPress: function keyPress(event) {
      var value = this.$().val(),
          iStart = this.$()[0].selectionStart,
          iEnd = this.$()[0].selectionEnd,
          charPressed = String.fromCharCode(event.charCode),
          newValue = value.substring(0, iStart) + charPressed + value.substring(iEnd);
      if (event.keyCode === 13) {
        return;
      }
      if (this.invalidKeyPressed(charPressed, newValue)) {
        event.stopImmediatePropagation();
        event.preventDefault();
      }
    },

    didInsertElement: function didInsertElement() {
      this._super();
      self = this;
    },

    changeDate: function changeDate() {
      self.get('onChange')();
      // console.log('.................................................................. changeDate');
    },

    input: function input() {
      self.get('onChange')();
      // console.log('.................................................................. input');
    },

    focusOut: function focusOut() {
      var memo = this.get('value');
      this.set('value', this.parseInputText());
      // console.log('.................................................................. ' + event.target.value);
      if (memo !== this.get('value')) {
        self.get('onChange')();
        // console.log('.................................................................. ' + memo + ' - ' + this.get('value'));
      }
    },

    invalidKeyPressed: function invalidKeyPressed(charPressed, newValue) {
      var ds = this.appManager.get('dateSeparator');
      var arr = newValue.split(ds);
      if (['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', ds].indexOf(charPressed) === -1) {
        return true;
      }
      if (arr.length > 3) {
        return true;
      }
      return false;
    },

    // Try to transform what user digited in a valid date ------------------------

    parseInputText: function parseInputText() {
      if (this.element.value.indexOf(this.appManager.get('dateSeparator')) === -1) {
        return this.parseOnlyNumbers();
      } else {
        return this.parseNumbersWithSeparator();
      }
    },

    parseOnlyNumbers: function parseOnlyNumbers() {
      var str = this.element.value;
      if (str.length === 1 || str.length === 2) {
        return this.createNewDate(str);
      } else if (str.length === 4) {
        return this.createNewDate(str.substring(0, 2), str.substring(2, 4));
      } else if (str.length === 6 || str.length === 8) {
        return this.createNewDate(str.substring(0, 2), str.substring(2, 4), str.substring(4));
      } else {
        return null;
      }
    },

    parseNumbersWithSeparator: function parseNumbersWithSeparator() {
      var arr = this.element.value.split(this.appManager.get('dateSeparator'));
      if (arr.length === 2) {
        return this.createNewDate(arr[0], arr[1]);
      } else if (arr.length === 3) {
        return this.createNewDate(arr[0], arr[1], arr[2]);
      } else {
        return null;
      }
    },

    createNewDate: function createNewDate(day, month, year) {
      var now = new Date();
      var wMonth = undefined;
      var wYear = undefined;
      var wStr = undefined;
      if (Number(day) === 0 || month && Number(month) === 0 || year && Number(year) === 0) {
        return null;
      }
      wMonth = (month ? Number(month) : now.getMonth() + 1).toString();
      wYear = (year ? Number(year) : now.getFullYear()).toString();
      if (wYear.length === 2) {
        wYear = '20' + wYear;
      }
      wStr = wYear + '-' + wMonth + '-' + day;
      return isNaN(Date.parse(wStr)) ? null : new Date(Number(wYear), Number(wMonth) - 1, Number(day));
    }

  });
});