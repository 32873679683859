define('banco/models/empresa', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({

    cnpj: _emberData['default'].attr('string'),
    razaoSocial: _emberData['default'].attr('string'),
    nomeFantasia: _emberData['default'].attr('string'),
    capitalSocialValor: _emberData['default'].attr('number'),
    capitalSocialAtualizacao: _emberData['default'].attr('date'),
    sitio: _emberData['default'].attr('string'),
    email: _emberData['default'].attr('string'),
    registroJuntaComercialNumero: _emberData['default'].attr('string'),
    registroJuntaComercialData: _emberData['default'].attr('date'),

    projeto: _emberData['default'].belongsTo('projeto', { async: true }),
    contato: _emberData['default'].belongsTo('contato', { async: true }),
    pessoaContato: _emberData['default'].belongsTo('pessoaContato', { async: true })

  });
});