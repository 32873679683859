define('banco/models/aviso', ['exports', 'ember', 'ember-data'], function (exports, _ember, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({

    conteudo: _emberData['default'].attr('string'),

    conteudoHtml: _ember['default'].computed('conteudo', function () {
      return _ember['default'].String.htmlSafe(this.get('conteudo'));
    })

  });
});