define('banco/models/agencia', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({

    codigo: _emberData['default'].attr('string'),
    nome: _emberData['default'].attr('string'),

    agenteOperador: _emberData['default'].belongsTo('agenteOperador', { async: true }),
    municipio: _emberData['default'].belongsTo('municipio', { async: true })

  });
});