define('banco/components/mb-view-server-error', ['exports', 'ember', 'banco/mixins/mb-view-base'], function (exports, _ember, _bancoMixinsMbViewBase) {
  exports['default'] = _ember['default'].Component.extend({ MbViewBase: _bancoMixinsMbViewBase['default'],

    focusNode: 'input',

    actions: {

      close: function close() {
        this.appManager.clearServerErrors();
      },
      handleCancel: function handleCancel() {
        this.appManager.clearServerErrors();
      }
    }
  });
});