define('banco/models/estado', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({

    nome: _emberData['default'].attr('string'),
    uf: _emberData['default'].attr('string'),
    codigo: _emberData['default'].attr('string'),

    municipios: _emberData['default'].hasMany('municipio', { async: true })

  });
});