define('banco/routes/pj/index', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {

    beforeModel: function beforeModel() {
      if (!this.appManager.get('currentUser')) {
        this.transitionTo('');
      } else if (this.appManager.get('currentUser.tipo') === 'funcionario_banco') {
        this.transitionTo('pf');
      } else {
        this.transitionTo('pj');
      }
    }

  });
});