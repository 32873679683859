define('banco/services/mb-app-manager', ['exports', 'ember', 'banco/config/environment', 'banco/utils/mb-error'], function (exports, _ember, _bancoConfigEnvironment, _bancoUtilsMbError) {
  exports['default'] = _ember['default'].Service.extend(_ember['default'].Evented, {

    session: _ember['default'].inject.service(),
    isBusy: false,
    isNotifying: false,
    serverErrors: [],

    dateSeparator: _bancoConfigEnvironment['default'].APP.dateSeparator,
    dateFormat: _bancoConfigEnvironment['default'].APP.dateFormat,
    decimalSeparator: _bancoConfigEnvironment['default'].APP.decimalSeparator,
    thousandSeparator: _bancoConfigEnvironment['default'].APP.thousandSeparator,
    language: _bancoConfigEnvironment['default'].APP.language,

    // Não sei porque o serviço notifications não está sendo injetado aqui. Por
    // isso criei essa propriedade aqui, que é setada em MbRouteApplication

    notifications: null,

    // Usuário corrente ----------------------------------------------------------

    currentUser: _ember['default'].computed('session.isAuthenticated', function () {
      if (this.get('session.isAuthenticated')) {
        return this.get('session.data.authenticated');
      }
      return null;
    }),

    // Reporta erro no servidor --------------------------------------------------

    addServerError: function addServerError(error) {
      this.get('serverErrors').pushObject(error);
    },

    // Limpa todos os erros de servidor ------------------------------------------

    clearServerErrors: function clearServerErrors() {
      this.set('serverErrors', []);
    },

    // Configurar notifications --------------------------------------------------

    configNotifications: function configNotifications(defaultAutoClear, defaultTimeout) {
      this.notifications.setDefaultAutoClear(defaultAutoClear);
      this.notifications.setDefaultClearNotification(defaultTimeout);
    },

    // Autenticação (via ember-simple-auth) --------------------------------------

    authenticate: function authenticate() {
      var _get,
          _this = this;

      this.disableInput();
      return (_get = this.get('session')).authenticate.apply(_get, arguments)['catch'](function (reason) {
        _this.notifications.warning(reason.error);
        _this.enableInput();
      });
    },

    // Fazer chamada ao servidor -------------------------------------------------

    askServer: function askServer(promise, success, error) {
      var self = this;
      self.disableInput();
      return promise.then(function (result) {
        self.enableInput();
        if (success) {
          success(result);
        }
      })['catch'](function (reason) {
        self.enableInput();
        if (error) {
          error(reason);
        }
      });
    },

    // Desabilitar interface -----------------------------------------------------

    disableInput: function disableInput() {
      this.set('isBusy', true);
    },

    // Habilitar interface -------------------------------------------------------

    enableInput: function enableInput() {
      this.set('isBusy', false);
    },

    // Mensagem de notificação e foco --------------------------------------------

    notify: function notify(notificationType, message) {

      var size = this.notifications.get('content').length;

      if (size > 0 && this.notifications.get('content')[size - 1].type === notificationType && this.notifications.get('content')[size - 1].message === message) {
        return;
      }

      this.set('isNotifying', true);

      switch (notificationType) {
        case 'info':
          this.notifications.info(message);
          break;
        case 'error':
          this.notifications.error(message);
          break;
        case 'success':
          this.notifications.success(message);
          break;
        case 'warning':
          this.notifications.warning(message);
          break;
      }
    },

    // Limpar todas as notificações ----------------------------------------------

    clearAllNotifications: function clearAllNotifications() {
      this.notifications.clearAll();
      _ember['default'].run.scheduleOnce('actions', this, function () {
        this.set('isNotifying', false);
      });
    },

    // Logout --------------------------------------------------------------------

    logout: function logout() {
      if (this.get('session.isAuthenticated')) {
        this.get('session').invalidate();
      }
    }

  });
});