define('banco/routes/pf/projetos/lista-em-analise/view/signatarios/new', ['exports', 'ember'], function (exports, _ember) {

  var Signatario = _ember['default'].Object.extend({
    CPF: '',
    nome: '',
    cargo: '',
    isProcurador: false,
    procurador: _ember['default'].computed('isProcurador', function () {
      return this.get('isProcurador') ? 'sim' : 'nao';
    })
  });
  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      var s1 = Signatario.create({
        CPF: '',
        nome: '',
        cargo: '',
        isProcurador: false
      });
      return s1;
    }
  });
});