define('banco/mixins/mb-view-base', ['exports', 'ember', 'ember-component-focus/mixins/focusable-component', 'ember-keyboard-shortcuts/mixins/component'], function (exports, _ember, _emberComponentFocusMixinsFocusableComponent, _emberKeyboardShortcutsMixinsComponent) {
  exports['default'] = _ember['default'].Mixin.create(_emberComponentFocusMixinsFocusableComponent['default'], _emberKeyboardShortcutsMixinsComponent['default'], {

    focusNode: '',

    keyboardShortcuts: {
      'esc': 'cancel'
    },

    actions: {

      cancel: function cancel() {
        if (!this.appManager.get('isNotifying')) {
          this.sendAction('handleCancel');
        }
      }

    },

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.focusAfterRender();
    },

    notifyAndSetFocus: function notifyAndSetFocus(notificationType, message, element) {
      this.appManager.notify(notificationType, message);
      this.focusNode = element;
      this.focusAfterRender();
    },

    setFocusNow: function setFocusNow(element) {
      this.focus(element);
    }

  });
});