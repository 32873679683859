define('banco/routes/pf/projetos/lista-em-analise/view/projeto/usos-e-fontes/usos/investimento-em-capital-fixo/new', ['exports', 'ember'], function (exports, _ember) {

  var InvestimentoEmCapital = _ember['default'].Object.extend({
    descricao: '',
    preExistente: 0,
    realizado: 0,
    aRealizar: 0,
    total: _ember['default'].computed('realizado', 'aRealizar', function () {
      var _realizado = Number(this.get('realizado')) || 0;
      var _aRealizar = Number(this.get('aRealizar')) || 0;
      return _realizado + _aRealizar;
    })
  });

  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      var ief1 = InvestimentoEmCapital.create({
        descricao: '',
        preExistente: 0,
        realizado: 0,
        aRealizar: 0
      });
      return ief1;
    }
  });
});