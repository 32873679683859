define('banco/routes/pf/projetos/lista-em-analise/view', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model(params) {
      return this.store.findRecord('projeto', params.id);
      // var Projeto = Ember.Object.extend({
      //   consultaPrevia:'',
      //   termoDeEnquadramento:'',
      //   dataLimite:'',
      //   empresaTitular:'',
      //   municipio:'',
      //   dataAprovacaoDaConsultaPrevia:'',
      //   numeroResolucaoSUDENE:'',
      //   dataDoPedido:'',
      //   ObservacaoPedido:'',
      //   nome:'',
      //   linhasDeProducao: [],
      //   destinosDeProducao: [],
      //   totalDeUsos:0,
      //   investimentosEmCapitalFixo: [],
      //   investimentosNaoFinanciaveis: [],
      //   investimentosEmCapitalCirculante: [],
      //   dataDosInvestimentosPreExistentes: '',
      //   dataDosInvestimentosRealizados: '',
      //   totalDeFontes:0,
      //   recursosProprios: [],
      //   recursosTerceiros: [],
      //   anoInicialDoConogramaFisico:0,
      //   anoFinalDoConogramaFisico:0,
      //   numeroDeParcelasDeReembolso:2,
      //   parcelasDeReembolso: [],
      //   arrayDeParcelasDeReembolso: Ember.computed('numeroDeParcelasDeReembolso', 'parcelasDeReembolso', function() {
      //     var _parcelas = [];
      //     var parcelasList = this.get('parcelasDeReembolso');
      //     for(var i=0; i<this.get('numeroDeParcelasDeReembolso'); i++) {
      //       var _parcela = parcelasList.findBy("parcela", i+1);
      //       if (!_parcela) {
      //         _parcela = Parcela.create({
      //           parcela: i+1,
      //           mes: '',
      //           valorDoPrincipal: 0,
      //           juros: 0,
      //           valorDaParcela: 0
      //         });
      //       }
      //       _parcelas.pushObject(_parcela);
      //     }
      //     return _parcelas;
      //   }),
      //   numeroDeParcelasDeDesembolso:1,
      //   parcelasDeDesembolso: [],
      //   arrayDeParcelasDeDesembolso: Ember.computed('numeroDeParcelasDeDesembolso', 'parcelasDeDesembolso', function() {
      //     var _parcelas = [];
      //     var parcelasList = this.get('parcelasDeDesembolso');
      //     for(var i=0; i<this.get('numeroDeParcelasDeDesembolso'); i++) {
      //       var _parcela = parcelasList.findBy("parcela", i+1);
      //       if (!_parcela) {
      //         _parcela = Parcela.create({
      //           parcela: i+1,
      //           mes: '',
      //           valorDoPrincipal: 0,
      //           juros: 0,
      //           valorDaParcela: 0
      //         });
      //       }
      //       _parcelas.pushObject(_parcela);
      //     }
      //     return _parcelas;
      //   }),
      //   signatarios: [],
      //   empregoDiretoEmImplantacao:'',
      //   empregoIndiretoEmImplantacao:'',
      //   empregoDiretoEmOperacaoPlena:'',
      //   empregoIndiretoEmOperacaoPlena:'',
      //   diretorias: []
      // });
      //
      // var projeto1 = Projeto.create({
      //   consultaPrevia:'test'+params.id,
      //   termoDeEnquadramento:'asfasfasefsef'+params.id,
      //   dataLimite:'01/01/2016'+params.id,
      //   empresaTitular:'babaaabababa'+params.id,
      //   municipio:'GG'+params.id,
      //   dataAprovacaoDaConsultaPrevia:'01/01/2014'+params.id,
      //   numeroResolucaoSUDENE:'3434343434'+params.id,
      //   dataDoPedido:'02/02/2015'+params.id,
      //   ObservacaoPedido:'blah'+params.id,
      //   nome: 'TEST projet nome '+params.id,
      //   linhasDeProducao: [],
      //   destinosDeProducao: [],
      //   totalDeUsos:40000000,
      //   investimentosEmCapitalFixo: [],
      //   investimentosNaoFinanciaveis: [],
      //   investimentosEmCapitalCirculante: [],
      //   dataDosInvestimentosPreExistentes: '02/02/2015',
      //   dataDosInvestimentosRealizados: '02/02/2015',
      //   totalDeFontes:50000000,
      //   recursosProprios: [],
      //   recursosTerceiros: [],
      //   anoInicialDoConogramaFisico:2015,
      //   anoFinalDoConogramaFisico:2019,
      //   numeroDeParcelasDeDesembolso:8,
      //   numeroDeParcelasDeReembolso:2,
      //   parcelasDeReembolso:[],
      //   parcelasDeDesembolso:[],
      //   signatarios:[],
      //   diretorias:[]
      // });
      //
      // var Signatario = Ember.Object.extend({
      //   id:'',
      //   CPF: '',
      //   nome: '',
      //   cargo: '',
      //   isProcurador: false,
      //   procurador: Ember.computed('isProcurador', function() {
      //     return this.get('isProcurador')?'sim':'nao';
      //   })
      // });
      // var s1 = Signatario.create({
      //   id:'123.123.123-10',
      //   CPF: '123.123.123-10',
      //   nome: 'signa um',
      //   cargo: 'Gerente Geral',
      //   isProcurador: false
      // });
      // var s2 = Signatario.create({
      //   id:'123.123.124-10',
      //   CPF: '123.123.124-10',
      //   nome: 'signa dois',
      //   cargo: 'Economista',
      //   isProcurador: true
      // });
      // projeto1.get('signatarios').pushObject(s1);
      // projeto1.get('signatarios').pushObject(s2);
      //
      // var Parcela = Ember.Object.extend({
      //   parcela: 0,
      //   mes: '',
      //   valorDoPrincipal: 0,
      //   juros: 0,
      //   valorDaParcela: 0
      // });
      // var pdr1 = Parcela.create({
      //   parcela: 1,
      //   mes: 'Janeiro / 2016',
      //   valorDoPrincipal: 10,
      //   juros: 12,
      //   valorDaParcela: 20
      // });
      // var pdr2 = Parcela.create({
      //   parcela: 2,
      //   mes: 'Fevereiro / 2016',
      //   valorDoPrincipal: 50,
      //   juros: 12,
      //   valorDaParcela: 12
      // });
      // projeto1.get('parcelasDeReembolso').pushObject(pdr1);
      // projeto1.get('parcelasDeReembolso').pushObject(pdr2);
      //
      // projeto1.get('parcelasDeDesembolso').pushObject(pdr2);
      //
      // var LinhaDeProducao = Ember.Object.extend({
      //   nome: '',
      //   capacidadeTotalInstalada: '',
      //   capacidadeTotalInstaladaAnterior: '',
      //   unidade: '',
      //   descricao:''
      // });
      // var ldp1 = LinhaDeProducao.create({
      //   nome: 'Produto 1',
      //   capacidadeTotalInstalada: 1200,
      //   capacidadeTotalInstaladaAnterior: 3,
      //   unidade: 'Un',
      //   descricao: 'bla',
      // });
      // var ldp2 = LinhaDeProducao.create({
      //   nome: 'Produto 2',
      //   capacidadeTotalInstalada: 400,
      //   capacidadeTotalInstaladaAnterior: 3,
      //   unidade: 'm2',
      //   descricao: 'bla',
      // });
      //
      // projeto1.get('linhasDeProducao').pushObject(ldp1);
      // projeto1.get('linhasDeProducao').pushObject(ldp2);
      //
      // var DestinoDeProducao = Ember.Object.extend({
      //   nome: '',
      //   percentualMercadoInterno: 0,
      //   destinoMercadoInterno: '',
      //   percentualMercadoExterno: 0,
      //   destinoMercadoExterno:''
      // });
      //
      // var ddp1 = DestinoDeProducao.create({
      //   nome: 'Produto 1',
      //   percentualMercadoInterno: 90,
      //   destinoMercadoInterno: 'PE',
      //   percentualMercadoExterno: 50,
      //   destinoMercadoExterno:'Brasil'
      // });
      //
      // var ddp2 = DestinoDeProducao.create({
      //   nome: 'Produto 2',
      //   percentualMercadoInterno: 70,
      //   destinoMercadoInterno: 'AL',
      //   percentualMercadoExterno: 70,
      //   destinoMercadoExterno:'Brasil'
      // });
      //
      // projeto1.get('destinosDeProducao').pushObject(ddp1);
      // projeto1.get('destinosDeProducao').pushObject(ddp2);
      //
      //
      // var InvestimentoEmCapital = Ember.Object.extend({
      //   id:0,
      //   descricao: '',
      //   classificacaoDeInvestimento:'',
      //   preExistente: 0,
      //   realizado: 0,
      //   aRealizar: 0,
      //   total: Ember.computed('realizado', 'aRealizar', function() {
      //     var _realizado = Number(this.get('realizado')) || 0;
      //     var _aRealizar = Number(this.get('aRealizar')) || 0;
      //     return _realizado + _aRealizar;
      //   })
      // });
      //
      // var ief1 = InvestimentoEmCapital.create({
      //   id:1,
      //   descricao: 'Bla bla bla 1',
      //   classificacaoDeInvestimento:'Classe A',
      //   preExistente: 100000,
      //   realizado: 2000000,
      //   aRealizar: 3000000,
      //   total:5000000
      // });
      // var ief2 = InvestimentoEmCapital.create({
      //   id:2,
      //   classificacaoDeInvestimento:'Classe B',
      //   descricao: 'Bla bla bla 1',
      //   preExistente: 200000,
      //   realizado: 3000000,
      //   aRealizar: 4000000,
      //   total:7000000
      // });
      //
      // projeto1.get('investimentosEmCapitalFixo').pushObject(ief1);
      // projeto1.get('investimentosEmCapitalFixo').pushObject(ief2);
      //
      //
      // var inf2 = InvestimentoEmCapital.create({
      //   id:3,
      //   descricao: 'Itens nao Financiaveis',
      //   classificacaoDeInvestimento:'Classe C',
      //   preExistente: 400000,
      //   realizado: 5000000,
      //   aRealizar: 6000000,
      //   total:7000000
      // });
      // projeto1.get('investimentosNaoFinanciaveis').pushObject(inf2);
      //
      // var icc1 = InvestimentoEmCapital.create({
      //   id:4,
      //   descricao: 'Capital de Giro',
      //   classificacaoDeInvestimento:'Classe D',
      //   preExistente: 400000,
      //   realizado: 5000000,
      //   aRealizar: 6000000,
      //   total:7000000
      // });
      // projeto1.get('investimentosEmCapitalCirculante').pushObject(icc1);
      //
      //
      // var rp1 = InvestimentoEmCapital.create({
      //   id:4,
      //   descricao: 'Meu recurso',
      //   classificacaoDeInvestimento:'Proprio',
      //   preExistente: 400000,
      //   realizado: 5000000,
      //   aRealizar: 6000000,
      //   total:7000000
      // });
      // projeto1.get('recursosProprios').pushObject(rp1);
      //
      // var rt1 = InvestimentoEmCapital.create({
      //   id:5,
      //   descricao: 'Seu recurso',
      //   classificacaoDeInvestimento:'Terceiro',
      //   preExistente: 400000,
      //   realizado: 5000000,
      //   aRealizar: 6000000,
      //   total:7000000
      // });
      // projeto1.get('recursosTerceiros').pushObject(rt1);
      //
      // return projeto1;
    }
  });
});