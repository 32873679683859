define('banco/routes/pf/projetos/lista-aguardando-autorizacao/view', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    prontoParaAutorizar: false,
    model: function model(params) {
      return this.store.findRecord('projeto', params.id);
    },
    actions: {
      prepararParaAutorizar: function prepararParaAutorizar() {
        var controller = this.get('controller');
        controller.set('prontoParaAutorizar', true);
      },
      autorizarPedido: function autorizarPedido() {
        var controller = this.get('controller');
        this.get("currentModel").save().then(function (projeto) {
          this.transitionTo('projetos.lista-aguardando-projeto');
        })['catch'](function (reason) {
          controller.set('mostrarErro', true);
        });
      },
      voltarParaViewMode: function voltarParaViewMode() {
        var controller = this.get('controller');
        controller.set('prontoParaAutorizar', false);
      }
    }
  });
});