define('banco/models/contato', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({

    logradouro: _emberData['default'].attr('string'),
    numero: _emberData['default'].attr('string'),
    complemento: _emberData['default'].attr('string'),
    bairro: _emberData['default'].attr('string'),
    cep: _emberData['default'].attr('string'),
    telefones: _emberData['default'].attr('string'),
    fax: _emberData['default'].attr('string'),
    comercial: _emberData['default'].attr('boolean'),

    municipio: _emberData['default'].belongsTo('municipio', { async: true })

  });
});