define('banco/initializers/inflector', ['exports', 'ember'], function (exports, _ember) {
  exports.initialize = initialize;

  function initialize() /* application */{
    // application.inject('route', 'foo', 'service:foo');
  }

  exports['default'] = {

    name: 'inflector',
    before: 'ember-cli-mirage',

    initialize: function initialize() {

      var inflector = _ember['default'].Inflector.inflector;

      inflector.irregular('acionista', 'acionistas');
      inflector.irregular('agente-operador', 'agentes-operadores');
      inflector.irregular('agencia', 'agencias');
      inflector.irregular('consulta-previa', 'consultas-previas');
      inflector.irregular('componente-conselho', 'componentes-conselho');
      inflector.irregular('componente-diretoria', 'componentes-diretoria');
      inflector.irregular('componente-grupo-economico', 'componentes-grupo-economico');
      inflector.irregular('configuracao', 'configuracoes');
      inflector.irregular('outras-informacoes-empresa', 'outras-informacoes-empresas');
      inflector.irregular('pessoa-contato', 'pessoas-contato');
    }

  };
});