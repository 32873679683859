define('banco/routes/pf/projetos/lista-em-analise/view/empresa-proponente/diretoria-e-conselho/conselhos-administrativos/new', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({

    model: function model(params) {
      var projeto = this.currentModel;
      return this.store.createRecord('componente-conselho', {
        projeto: projeto
      });
    },
    actions: {
      save: function save(contato) {
        this.transitionTo('pf.projetos.lista-em-analise.view.empresa-proponente.diretoria-e-conselho');
        // var projeto = this.currentModel;
        // var conselho = this.store.createRecord('componente-conselho', {
        //   cpf: contato.cpf,
        //   nome: contato.nome,
        //   cargo: contato.cargo,
        //   mandatoInicio: contato.mandatoInicio,
        //   mandatoFim: contato.mandatoFim,
        //   projeto: projeto
        // });
        // conselho.save().then(function(){
        //   this.transitionTo('pf.projetos.lista-em-analise.view.empresa-proponente.diretoria-e-conselho');
        // });
      },
      cancel: function cancel(contato) {
        contato.deleteRecord();
        this.transitionTo('pf.projetos.lista-em-analise.view.empresa-proponente.diretoria-e-conselho');
      }
    }
  });
});