define('banco/helpers/style-header', ['exports', 'ember'], function (exports, _ember) {
            exports.styleHeader = styleHeader;

            function styleHeader(cores) {
                        var style = 'background-color: #' + cores[0] + '; border-bottom: 3px solid #' + cores[1] + '; color: #' + cores[2];
                        var escaped = _ember['default'].Handlebars.Utils.escapeExpression(style);
                        return new _ember['default'].Handlebars.SafeString(escaped);
            }

            exports['default'] = _ember['default'].Helper.helper(styleHeader);
});