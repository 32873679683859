define('banco/models/componente-conselho', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({

    cpf: _emberData['default'].attr('string'),
    nome: _emberData['default'].attr('string'),
    cargo: _emberData['default'].attr('string'),
    mandatoInicio: _emberData['default'].attr('date'),
    mandatoFim: _emberData['default'].attr('date'),

    projeto: _emberData['default'].belongsTo('projeto', { async: true })

  });
});