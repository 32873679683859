define('banco/routes/pf/projetos/lista-em-analise/index', ['exports', 'ember', 'banco/helpers/rota-de-visualizacao-para'], function (exports, _ember, _bancoHelpersRotaDeVisualizacaoPara) {

  var Projeto = _ember['default'].Object.extend({
    nome: '',
    registro: '',
    consultaPrevia: '',
    dataLimite: ''
  });

  var ProjetoCollection = _ember['default'].ArrayProxy.extend({
    sortProperties: ['dataLimite'],
    sortAscending: false,
    content: []
  });

  var projeto1 = Projeto.create({
    id: 4,
    nome: 'Uma empresa que aguarda S.A. em analise',
    registro: '12.334.445/2343-49 - PE',
    consultaPrevia: 'B334R3',
    dataLimite: '23/09/2015',
    termoDeEnquadramento: 'asfasfasefsef',
    empresaTitular: 'babaaabababa',
    municipio: 'GG',
    dataAprovacaoDaConsultaPrevia: '01/01/2014',
    numeroResolucaoSUDENE: '3434343434',
    dataDoPedido: '02/02/2015',
    ObservacaoPedido: 'blah',
    status: "Em_Analise",
    linhasDeProducao: []
  });
  var projeto2 = Projeto.create({
    id: 3,
    nome: 'Gertrudes macale inc. em analise',
    registro: '12.334.445/2343-49 - PE',
    consultaPrevia: 'CGD556',
    dataLimite: '21/08/2015',
    termoDeEnquadramento: 'asfasfasefsef',
    empresaTitular: 'babaaabababa',
    municipio: 'GG',
    dataAprovacaoDaConsultaPrevia: '01/01/2014',
    numeroResolucaoSUDENE: '3434343434',
    dataDoPedido: '02/02/2015',
    ObservacaoPedido: 'blah',
    status: "Em_Analise",
    linhasDeProducao: []
  });

  var LinhaDeProducao = _ember['default'].Object.extend({
    nome: '',
    capacidadeTotalInstalada: '',
    capacidadeTotalInstaladaAnterior: '',
    unidade: '',
    descricao: ''
  });

  // var LinhaDeProducaoCollection = Ember.ArrayProxy.extend( {
  //   sortProperties: ['dataLimite'],
  //   sortAscending: false,
  //   content: []
  // });

  var ldp1 = LinhaDeProducao.create({
    nome: 'Produto 1',
    capacidadeTotalInstalada: 1200,
    capacidadeTotalInstaladaAnterior: 3,
    unidade: 'Un',
    descricao: 'bla'
  });
  var ldp2 = LinhaDeProducao.create({
    nome: 'Produto 2',
    capacidadeTotalInstalada: 400,
    capacidadeTotalInstaladaAnterior: 3,
    unidade: 'm2',
    descricao: 'bla'
  });
  var ldp3 = LinhaDeProducao.create({
    nome: 'Produto 3',
    capacidadeTotalInstalada: 9999,
    capacidadeTotalInstaladaAnterior: 3,
    unidade: 'Kg',
    descricao: 'bla'
  });

  projeto1.get('linhasDeProducao').pushObject(ldp1);
  projeto1.get('linhasDeProducao').pushObject(ldp2);

  projeto2.get('linhasDeProducao').pushObject(ldp2);
  projeto2.get('linhasDeProducao').pushObject(ldp3);

  var projetos = ProjetoCollection.create();
  projetos.pushObject(projeto1);
  projetos.pushObject(projeto2);
  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      return this.store.findAll('projeto');
      // return projetos;
    },

    actions: {
      editClicked: function editClicked(projeto) {
        // this.transitionTo(rotaDeVisualizacaoPara(projeto), projeto.id);
        // this.transitionTo(rotaDeVisualizacaoPara(projeto), projeto);
        this.transitionTo('pf.projetos.lista-em-analise.view', projeto.id);
      }
    }
  });
});