define('banco/models/projeto', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({

    codigo: _emberData['default'].attr('string'),
    dataPedidoAutorizacao: _emberData['default'].attr('date'),
    obsPedidoAutorizacao: _emberData['default'].attr('string'),
    dataAutorizacao: _emberData['default'].attr('date'),
    obsAutorizacao: _emberData['default'].attr('string'),
    dataRecebimento: _emberData['default'].attr('date'),
    obsRecebimento: _emberData['default'].attr('string'),
    justificativaDifCnpj: _emberData['default'].attr('string'),
    urlJustificativa: _emberData['default'].attr('string'),

    agencia: _emberData['default'].belongsTo('agencia', { async: true }),
    agenteOperador: _emberData['default'].belongsTo('agenteOperador', { async: true }),
    consultaPrevia: _emberData['default'].belongsTo('consultaPrevia', { async: true }),

    empresa: _emberData['default'].hasMany('empresa', { async: true }),
    outrasInformacoesEmpresa: _emberData['default'].hasMany('outrasInformacoesEmpresa', { async: true }),

    acionistas: _emberData['default'].hasMany('acionista', { async: true }),
    componentesConselho: _emberData['default'].hasMany('componenteConselho', { async: true }),
    componentesDiretoria: _emberData['default'].hasMany('componenteDiretoria', { async: true }),
    componentesGrupoEconomico: _emberData['default'].hasMany('componenteGrupoEconomico', { async: true }),
    signatarios: _emberData['default'].hasMany('signatario', { async: true })

  });
});