define('banco/components/mb-input-label', ['exports', 'ember', 'banco/helpers/generate-uuid'], function (exports, _ember, _bancoHelpersGenerateUuid) {
  exports['default'] = _ember['default'].Component.extend({

    classNames: ['form-group'],

    field: null,
    displayText: '',
    isDisabled: false,
    isMultiline: false,
    isInlineText: false,
    isUppercase: false,
    idInput: '',
    maxlength: 524288,
    first: false,
    last: false,
    size: 5,

    onChange: function onChange() {},

    inlineLabelSize: _ember['default'].computed('isInlineText', 'size', function () {
      return 12 - this.get('size');
    }),

    idInputElement: _ember['default'].computed('idInput', function () {
      var idInput = this.get('idInput');
      return idInput === '' ? 'mab' + _bancoHelpersGenerateUuid['default'].compute() : idInput;
    }),

    cssClass: _ember['default'].computed('isUppercase', function () {
      return _ember['default'].String.htmlSafe('form-control mousetrap' + (this.get('isUppercase') ? ' uppercase' : ''));
    }),

    cssClassFirst: _ember['default'].computed('first', function () {
      return this.get('first') ? 'first' : '';
    }),

    cssClassLast: _ember['default'].computed('last', function () {
      return this.get('last') ? 'last' : '';
    }),

    actions: {
      onChange: function onChange() {
        this.get('onChange')();
      }
    }

  });
});