define('banco/helpers/format-seq-ano', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Helper.extend({

    compute: function compute(params) {
      var seq = typeof params[0] === 'undefined' ? 0 : params[0];
      if (seq === 0) {
        return '';
      }
      return seq.toString() + '/' + params[1].getFullYear().toString();
    }

  });
});