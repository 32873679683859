define('banco/helpers/generate-uuid', ['exports', 'ember'], function (exports, _ember) {
    exports.generateUuid = generateUuid;

    function generateUuid() {
        var d = new Date().getTime();
        var uuid = 'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = (d + Math.random() * 16) % 16 | 0;
            d = Math.floor(d / 16);
            return (c === 'x' ? r : r & 0x3 | 0x8).toString(16);
        });
        return uuid;
    }

    exports['default'] = _ember['default'].Helper.helper(generateUuid);
});