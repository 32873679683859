define('banco/instance-initializers/ember-devtools', ['exports', 'banco/config/environment'], function (exports, _bancoConfigEnvironment) {
  exports['default'] = {
    initialize: function initialize(appInstance) {
      var service = 'service:ember-devtools';
      var devTools = appInstance.lookup ? appInstance.lookup(service)
      // backwards compatibility < 2.1
      : appInstance.container.lookup(service);
      var devToolsConfig = _bancoConfigEnvironment['default']['ember-devtools'] || {};
      if (devToolsConfig.global === true) {
        devTools.globalize();
      } else if (devToolsConfig.global) {
        window[devToolsConfig.global] = devTools;
      }
    }
  };
});
/* global window */