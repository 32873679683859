define('banco/mirage/fixtures/configuracoes', ['exports'], function (exports) {
  exports['default'] = [{
    'id': 1,
    'nome-empresa': 'Superintendência do Desenvolvimento do Nordeste',
    'sigla-empresa': 'SUDENE',
    'nome-sistema': 'Sistema de Informação e gestão do Fundo de Desenvolvimento do Nordeste',
    'sigla-sistema': 'SigFDNE',
    'cor-topo': '00631e',
    'cor-borda': 'FFB400',
    'cor-fonte': 'FFFFFF',
    'nome-fundo': 'Fundo de Desenvolvimento do Nordeste',
    'sigla-fundo': 'FDNE',
    'path-logo': '/uploads/configuracoes/logo_empresa/logo.png',
    'path-logo-impressao': '/uploads/configuracoes/logo_impressao/logo_report.png'
  }];
});