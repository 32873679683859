define('banco/models/componente-grupo-economico', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({

    cnpj: _emberData['default'].attr('string'),
    razaoSocial: _emberData['default'].attr('string'),
    observacao: _emberData['default'].attr('string'),

    projeto: _emberData['default'].belongsTo('projeto', { async: true })

  });
});