define('banco/components/form-protocolar-pedido', ['exports', 'ember', 'banco/mixins/mb-view-base'], function (exports, _ember, _bancoMixinsMbViewBase) {
  exports['default'] = _ember['default'].Component.extend(_bancoMixinsMbViewBase['default'], {

    focusNode: 'input',
    classFormPedido: 'ax-box ax-hidden',
    classBtnBuscar: 'btn btn-lg btn-primary',
    classBtnSalvar: 'btn btn-lg',
    disabledBtnBuscar: '',
    showBotaoCancelarSuperior: true,
    store: _ember['default'].inject.service(),
    projeto: null,

    // Actions -------------------------------------------------------------------

    actions: {

      didTransition: function didTransition() {
        this.showFormPedido();
      },

      procurarCartaConsulta: function procurarCartaConsulta(codigo, termoEnquadramento) {

        var params = { codigo: codigo, termoEnquadramento: termoEnquadramento };
        var self = this;

        if (codigo === '') {
          this.notifyAndSetFocus('warning', 'Informe o código da Consulta Prévia.', '#codigoCP');
          return;
        }

        if (termoEnquadramento === '') {
          this.notifyAndSetFocus('warning', 'Informe o Termo de Enquadramento.', '#termoEnquadramento');
          return;
        }

        self.appManager.askServer(self.get('store').queryRecord('consultaPrevia', params), function (result) {
          var agenteOperador = self.get('store').createRecord('agenteOperador');
          var agencia = self.get('store').createRecord('agencia');
          if (result.get('length') > 0) {
            self.set('model.consultaPrevia', result.get('firstObject'));
            agenteOperador.set('id', self.appManager.get('currentUser.agenteOperador.id'));
            self.set('model.agenteOperador', agenteOperador);
            if (self.appManager.get('currentUser.agencia')) {
              agencia.set('id', self.appManager.get('currentUser.agencia.id'));
              self.set('model.agencia', agencia);
            }
            self.showFormPedido();
          } else {
            self.hideFormPedido(true, 'Consulta prévia não encontrada.');
          }
        }, function (reason) {
          self.hideFormPedido(true, 'Consulta prévia não encontrada - ' + reason.message);
        });
      },

      protocolarPedido: function protocolarPedido() {
        var self = this;
        this.setFocusNow('#observacoes');
        if (!self.validarForm()) {
          return;
        }
        this.appManager.askServer(this.get('model').save(), function () {
          self.sendAction('handleOk');
        });
      },

      argsBuscaModificados: function argsBuscaModificados() {
        this.hideFormPedido(false);
      }

    },

    // Funções auxiliares --------------------------------------------------------

    showFormPedido: function showFormPedido() {
      this.set('classFormPedido', 'ax-box');
      this.set('classBtnBuscar', 'btn btn-lg');
      this.set('classBtnSalvar', 'btn btn-lg btn-primary');
      this.set('disabledBtnBuscar', 'disabled');
      this.set('showBotaoCancelarSuperior', false);
      this.focusNode = '#dataPedido';
      this.focusAfterRender();
    },

    hideFormPedido: function hideFormPedido(setFocus, mensagem) {
      this.set('classFormPedido', 'ax-box ax-hidden');
      this.set('classBtnBuscar', 'btn btn-lg btn-primary');
      this.set('classBtnSalvar', 'btn btn-lg');
      this.set('disabledBtnBuscar', '');
      this.set('showBotaoCancelarSuperior', true);
      if (setFocus) {
        this.focusNode = '#codigoCP';
        this.focusAfterRender();
      }
      if (mensagem) {
        this.notifyAndSetFocus('warning', mensagem);
      }
    },

    validarForm: function validarForm() {
      var inputDataPedido = this.$('#dataPedido')[0];
      if (!inputDataPedido.validity.valid || inputDataPedido.value === '') {
        this.notifyAndSetFocus('warning', 'Data do pedido inválida.', '#dataPedido');
        return false;
      }
      return true;
    }

  });
});