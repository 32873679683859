define('banco/components/menu-de-navegacao', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({

    currentPage: "",

    isAguardandoAutorizacao: _ember['default'].computed('currentPage', function () {
      return this.get('currentPage') === 'Aguardando Autorizacao';
    }),

    isAguardandoProjeto: _ember['default'].computed('currentPage', function () {
      return this.get('currentPage') === 'Aguardando Projeto';
    }),

    isEmAnalise: _ember['default'].computed('currentPage', function () {
      return this.get('currentPage') === 'Em Analise';
    }),

    isAprovado: _ember['default'].computed('currentPage', function () {
      return this.get('currentPage') === 'Aprovado';
    }),

    isArquivado: _ember['default'].computed('currentPage', function () {
      return this.get('currentPage') === 'Arquivado';
    }),

    isDesistencia: _ember['default'].computed('currentPage', function () {
      return this.get('currentPage') === 'Desistencia';
    })

  });
});