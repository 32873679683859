define('banco/components/lista-de-projetos', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['lista-de-projetos'],
    projetos: null,
    actions: {
      goToView: function goToView(projeto) {

        // this.transitionTo(TransicaoDoProjeto[projeto.status], projeto);
        // switch (projeto.status) {
        //   case "Aguardando_Autorizacao":
        //     this.controller.transitionToRoute('projetos.lista-aguardando-autorizacao.view', projeto);
        //     // this.transitionTo('projetos.lista-aguardando-autorizacao.view', projeto);
        //     break;
        //   case "Aguardando_Projeto":
        //     this.transitionTo('projetos.lista-aguardando-projeto.view', projeto);
        //     break;
        //   case "Em_Analise":
        //     this.transitionTo('projetos.lista-em-analise.view', projeto);
        //     break;
        //   default:
        // }
        this.sendAction('edit-clicked', projeto);
      }
    }
  });
});