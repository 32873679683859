define('banco/helpers/format-cnpj', ['exports', 'ember'], function (exports, _ember) {
  exports.formatCnpj = formatCnpj;

  function formatCnpj(params) {
    var cnpj = params.toString();
    if (cnpj.length !== 14) {
      return cnpj;
    } else {
      return cnpj.substr(0, 2) + '.' + cnpj.substr(2, 3) + '.' + cnpj.substr(5, 3) + '/' + cnpj.substr(8, 4) + '-' + cnpj.substr(12, 2);
    }
  }

  exports['default'] = _ember['default'].Helper.helper(formatCnpj);
});