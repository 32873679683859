define('banco/components/form-controle-societario', ['exports', 'banco/components/form-base'], function (exports, _bancoComponentsFormBase) {
  exports['default'] = _bancoComponentsFormBase['default'].extend({});

  // import Ember from 'ember';
  //
  // export default Ember.Component.extend({
  //   classNames: ['form-group'],
  //   model: {},
  //   header: '',
  //   actions: {
  //     save(acionista){
  //       this.sendAction('onSave', acionista);
  //     }
  //   }
  // });
});
// import Ember from 'ember';