define('banco/routes/pf/protocolar-pedido', ['exports', 'ember', 'banco/mixins/mb-route-authenticated'], function (exports, _ember, _bancoMixinsMbRouteAuthenticated) {
  exports['default'] = _ember['default'].Route.extend(_bancoMixinsMbRouteAuthenticated['default'], {

    model: function model() {
      return this.store.createRecord('projeto');
    },

    actions: {

      handleOk: function handleOk() {
        this.transitionTo('pf.projetos.lista-aguardando-autorizacao');
      },

      handleCancel: function handleCancel() {
        this.transitionTo('pf.projetos.lista-aguardando-autorizacao');
      },

      clearStore: function clearStore() {
        if (this.get('currentModel.isNew')) {
          this.store.deleteRecord(this.currentModel);
        }
      }

    }

  });
});