define('banco/mirage/fixtures/projetos', ['exports'], function (exports) {
  exports['default'] = [{
    id: 1, codigo: '1', 'data-pedido-autorizacao': '01/01/2001',
    'obs-pedido-autorizacao': 'obs do pedido',
    // 'data-autorizacao': 1 ,
    // 'obsAutorizacao': 1 ,
    // 'data-recebimento': '' ,
    // 'obs-recebimento': 1 ,
    'justificativa-dif-cnpj': '2323.232.32.32.32',
    'url-justificativa': 'justtificativa',
    'consulta-previa-id': 1,
    'empresa-id': 1,
    'agente-operador-id': 1,
    'agencia-id': 1
  }, {
    id: 2, codigo: '2', 'data-pedido-autorizacao': '01/01/2001',
    'obs-pedido-autorizacao': 'obs do pedido',
    // 'data-autorizacao': 1 ,
    // 'obsAutorizacao': 1 ,
    // 'data-recebimento': '' ,
    // 'obs-recebimento': '' ,
    'justificativa-dif-cnpj': '2323.232.32.32.32',
    'url-justificativa': 'justtificativa',
    'consulta-previa-id': 2,
    'empresa-id': 2,
    'agente-operador-id': 2,
    'agencia-id': 2
  }];
});