define('banco/routes/index', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({

    beforeModel: function beforeModel() {
      if (this.appManager.get('currentUser')) {
        if (this.appManager.get('currentUser.tipo') === 'funcionario_banco') {
          this.transitionTo('pf');
        } else {
          this.transitionTo('pj');
        }
      } else {
        this.transitionTo('login');
      }
    }

  });
});