define('banco/routes/pf/projetos/lista-em-analise/view/projeto/cronogramas/desembolso', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    editarParcelas: false,
    numeroDeParcelas: 0,
    actions: {
      entrarEmModoEdicaoDeParcelas: function entrarEmModoEdicaoDeParcelas(numeroDeParcelasAtual) {
        var controller = this.get('controller');
        controller.set('editarParcelas', true);
        controller.set('numeroDeParcelas', numeroDeParcelasAtual);
      },
      sairDoModoEdicaoDeParcelas: function sairDoModoEdicaoDeParcelas(item, numeroDeParcelasNovo) {
        var controller = this.get('controller');
        // var model = this.get('model');
        item.set('numeroDeParcelasDeDesembolso', numeroDeParcelasNovo);
        controller.set('editarParcelas', false);
      }
    }
  });
});