define('banco/routes/pf/projetos/lista-em-analise/view/projeto/cronogramas/fisico', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    editarDatas: false,
    actions: {
      entrarEmModoEdicaoDeDatas: function entrarEmModoEdicaoDeDatas() {
        var controller = this.get('controller');
        controller.set('editarDatas', true);
      },
      sairDoModoEdicaoDeDatas: function sairDoModoEdicaoDeDatas() {
        var controller = this.get('controller');
        controller.set('editarDatas', false);
      }
    }
  });
});