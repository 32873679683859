define('banco/components/mb-input-date-label', ['exports', 'ember', 'banco/helpers/generate-uuid'], function (exports, _ember, _bancoHelpersGenerateUuid) {
  exports['default'] = _ember['default'].Component.extend({

    classNames: ['form-group'],

    field: null,
    displayText: '',
    isDisabled: false,
    isInlineText: false,
    idInput: '',
    first: false,
    last: false,
    size: 5,
    format: 'dd/mm/yyyy',

    inlineLabelSize: _ember['default'].computed('isInlineText', 'size', function () {
      return 12 - this.get('size');
    }),

    idInputElement: _ember['default'].computed('idInput', function () {
      var idInput = this.get('idInput');
      return idInput === '' ? 'mab' + _bancoHelpersGenerateUuid['default'].compute() : idInput;
    }),

    cssClassFirst: _ember['default'].computed('first', function () {
      return this.get('first') ? 'first' : '';
    }),

    cssClassLast: _ember['default'].computed('last', function () {
      return this.get('last') ? 'last' : '';
    }),

    keyDown: function keyDown(event) {
      var newEvent;
      if (event.keyCode === 32) {
        newEvent = $.Event('keydown');
        newEvent.keyCode = 27;
        this.$('input').trigger(newEvent);
        event.preventDefault();
        event.stopPropagation();
      }
    },

    keyUp: function keyUp(event) {
      var newEvent;
      if (event.keyCode === 27) {
        event.preventDefault();
        event.stopPropagation();
        newEvent = $.Event('keydown');
        newEvent.keyCode = 27;
        $(event.target).trigger(newEvent);
        this.sendAction('cancel');
      }
    }

  });
});