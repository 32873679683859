define('banco/instance-initializers/ember-intl', ['exports', 'banco/config/environment'], function (exports, _bancoConfigEnvironment) {
  exports.instanceInitializer = instanceInitializer;

  function filterBy(env, type) {
    return Object.keys(requirejs._eak_seen).filter(function (key) {
      return key.indexOf(env.modulePrefix + '/' + type + '/') === 0;
    });
  }

  function instanceInitializer(instance) {
    var container = instance.lookup ? instance : instance.container;
    var service = container.lookup('service:intl');

    filterBy(_bancoConfigEnvironment['default'], 'cldrs').forEach(function (key) {
      service.addLocaleData(require(key, null, null, true)['default']);
    });

    filterBy(_bancoConfigEnvironment['default'], 'translations').forEach(function (key) {
      var localeSplit = key.split('\/');
      var localeName = localeSplit[localeSplit.length - 1];
      service.addTranslations(localeName, require(key, null, null, true)['default']);
    });
  }

  exports['default'] = {
    name: 'ember-intl',
    initialize: instanceInitializer
  };
});
/**
 * Copyright 2015, Yahoo! Inc.
 * Copyrights licensed under the New BSD License. See the accompanying LICENSE file for terms.
 */