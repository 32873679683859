define('banco/components/form-login', ['exports', 'ember', 'banco/config/environment', 'banco/mixins/mb-view-base'], function (exports, _ember, _bancoConfigEnvironment, _bancoMixinsMbViewBase) {
  exports['default'] = _ember['default'].Component.extend(_bancoMixinsMbViewBase['default'], {

    focusNode: 'input',

    apiHost: _bancoConfigEnvironment['default'].apiHost,
    avisos: [],
    tipo: '',

    identification: '',
    email: '',
    password: '',

    tipoPf: _ember['default'].computed('tipo', function () {
      return this.get('tipo') === 'pf';
    }),

    actions: {

      alternarLogin: function alternarLogin() {
        this.set('identification', '');
        this.set('email', '');
        this.set('password', '');
        this.set('tipo', this.get('tipo') === 'pf' ? 'pj' : 'pf');
        this.focusAfterRender();
      },

      authenticate: function authenticate() {
        var _getProperties = this.getProperties('identification', 'password', 'email');

        var identification = _getProperties.identification;
        var password = _getProperties.password;
        var email = _getProperties.email;

        if (_bancoConfigEnvironment['default'].environment === 'development' && !identification) {
          identification = this.get('tipo') === 'pf' ? '12345678991' : '00110220000130|cnpj@bancoteste.com.br';
          password = 'secret123';
        } else {
          if (this.get('email')) {
            identification += '|' + email;
          }
        }
        return this.appManager.authenticate('authenticator:devise', identification, password);
      }
    }

  });
});