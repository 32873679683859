define('banco/models/configuracao', ['exports', 'ember', 'ember-data', 'banco/config/environment'], function (exports, _ember, _emberData, _bancoConfigEnvironment) {
  exports['default'] = _emberData['default'].Model.extend({

    nomeEmpresa: _emberData['default'].attr('string'),
    siglaEmpresa: _emberData['default'].attr('string'),
    nomeSistema: _emberData['default'].attr('string'),
    siglaSistema: _emberData['default'].attr('string'),
    nomeFundo: _emberData['default'].attr('string'),
    siglaFundo: _emberData['default'].attr('string'),
    corTopo: _emberData['default'].attr('string'),
    corBorda: _emberData['default'].attr('string'),
    corFonte: _emberData['default'].attr('string'),
    pathLogo: _emberData['default'].attr('string'),
    pathLogoImpressao: _emberData['default'].attr('string'),

    urlLogo: _ember['default'].computed('pathLogo', function () {
      return _bancoConfigEnvironment['default'].apiHost + this.get('pathLogo');
    }),

    urlLogoImpressao: _ember['default'].computed('pathLogoImpressao', function () {
      return _bancoConfigEnvironment['default'].apiHost + this.get('pathLogoImpressao');
    })

  });
});