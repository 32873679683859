define('banco/models/consulta-previa', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({

    codigo: _emberData['default'].attr('string'),
    dataAprovacao: _emberData['default'].attr('date'),
    sequencialTermoEnquadramento: _emberData['default'].attr('number'),
    numeroResolucao: _emberData['default'].attr('number'),
    cnpjEmpresaTitular: _emberData['default'].attr('string'),
    razaoSocialEmpresaTitular: _emberData['default'].attr('string'),
    municipioEmpresaTitular: _emberData['default'].attr('string')

  });
});