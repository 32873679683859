define('banco/routes/pf/projetos/lista-em-analise/view/empresa-proponente/dados-gerais', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    actions: {
      salvar: function salvar() {
        this.get("currentModel").save().then(function (projeto) {
          this.transitionTo('projetos.lista-aguardando-autorizacao');
        })['catch'](function (reason) {
          this.set('mostrarErro', true);
        });
      }
    }
  });
});