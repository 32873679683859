define("banco/templates/components/form-signatario", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "fragmentReason": {
          "name": "triple-curlies"
        },
        "revision": "Ember@2.5.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 22,
            "column": 0
          }
        },
        "moduleName": "banco/templates/components/form-signatario.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("form");
        dom.setAttribute(el1, "class", "form-horizotal");
        dom.setAttribute(el1, "role", "form");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "ax-clear");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "ax-v-space-10");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "col-sm-3");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("input");
        dom.setAttribute(el3, "type", "checkbox");
        dom.setAttribute(el3, "name", "procurador");
        dom.setAttribute(el3, "value", "model.procurador");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("Procurador");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("br");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "form-group");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "pull-right");
        dom.setAttribute(el3, "style", "margin-top: 20px;");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("button");
        dom.setAttribute(el4, "class", "btn btn-lg");
        var el5 = dom.createTextNode("Cancel");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("button");
        dom.setAttribute(el4, "class", "btn btn-lg");
        var el5 = dom.createTextNode("Salvar");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [22, 1]);
        var element2 = dom.childAt(element1, [1]);
        var element3 = dom.childAt(element1, [3]);
        var morphs = new Array(8);
        morphs[0] = dom.createMorphAt(element0, 1, 1);
        morphs[1] = dom.createMorphAt(element0, 4, 4);
        morphs[2] = dom.createMorphAt(element0, 6, 6);
        morphs[3] = dom.createMorphAt(element0, 8, 8);
        morphs[4] = dom.createMorphAt(element0, 10, 10);
        morphs[5] = dom.createMorphAt(element0, 12, 12);
        morphs[6] = dom.createElementMorph(element2);
        morphs[7] = dom.createElementMorph(element3);
        return morphs;
      },
      statements: [["inline", "mb-view-section-header", [], ["text", ["subexpr", "@mut", [["get", "header", ["loc", [null, [2, 34], [2, 40]]]]], [], []], "useLineDivision", true], ["loc", [null, [2, 2], [2, 63]]]], ["inline", "mb-input", [], ["field", ["subexpr", "@mut", [["get", "model.nome", ["loc", [null, [4, 21], [4, 31]]]]], [], []], "displayText", "Nome", "size", "6"], ["loc", [null, [4, 2], [4, 61]]]], ["inline", "mb-input", [], ["field", ["subexpr", "@mut", [["get", "model.cargo", ["loc", [null, [6, 21], [6, 32]]]]], [], []], "displayText", "Cargo/Funcao", "size", "6"], ["loc", [null, [6, 2], [6, 70]]]], ["inline", "mb-input", [], ["field", ["subexpr", "@mut", [["get", "model.unidadeOrganizacional", ["loc", [null, [7, 21], [7, 48]]]]], [], []], "displayText", "Unidade Organizacional", "size", "9"], ["loc", [null, [7, 2], [7, 96]]]], ["inline", "mb-input", [], ["field", ["subexpr", "@mut", [["get", "model.sigla", ["loc", [null, [8, 21], [8, 32]]]]], [], []], "displayText", "Sigla", "size", "3"], ["loc", [null, [8, 2], [8, 63]]]], ["inline", "mb-input", [], ["field", ["subexpr", "@mut", [["get", "model.preambulo", ["loc", [null, [9, 21], [9, 36]]]]], [], []], "displayText", "Preambulo", "size", "3"], ["loc", [null, [9, 2], [9, 71]]]], ["element", "action", ["cancel", ["get", "model", ["loc", [null, [17, 51], [17, 56]]]]], [], ["loc", [null, [17, 33], [17, 58]]]], ["element", "action", ["save", ["get", "model", ["loc", [null, [18, 49], [18, 54]]]]], [], ["loc", [null, [18, 33], [18, 56]]]]],
      locals: [],
      templates: []
    };
  })());
});