define('banco/helpers/format-cnpj-razao-social', ['exports', 'ember', 'banco/helpers/format-cnpj'], function (exports, _ember, _bancoHelpersFormatCnpj) {
  exports['default'] = _ember['default'].Helper.extend({

    compute: function compute(params) {
      var cnpj = typeof params[0] === 'undefined' ? '' : params[0];
      if (cnpj === '') {
        return '';
      }
      return _bancoHelpersFormatCnpj['default'].compute(cnpj) + ' - ' + params[1];
    }

  });
});